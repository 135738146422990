<template>
  <div class="page">
    <div v-if="getModuleConfig('external_member_customer_bind.not_register.step_section.enable_step_section')" class="p-3">
    <SharedStep :steps="getModuleConfig('thanks_page.step_section.steps')" />
    </div>

    <div v-if="getModuleConfig('external_member_customer_bind.not_register.form_title')" class="page__title">
    {{ getModuleConfig("external_member_customer_bind.not_register.form_title") }}
    </div>
    <div v-if="getModuleConfig('external_member_customer_bind.not_register.form_desc')" class="page__desc">
    {{ getModuleConfig("external_member_customer_bind.not_register.form_desc") }}
    </div>

    <div class="page__content" v-if="contentData" v-html="contentData"></div>

    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
        <div> <!-- redirect button -->
          <SharedButton class="s-btn-outline-primary bg-transparent" @click="redirectToIntended" v-if="showRedirectButton && !redirectAction">
            {{ redirectActionConfig.button_text }}
          </SharedButton>
          <MemberButton
            v-if="redirectAction && showRedirectButton"
            :button-style='`s-btn-outline-primary`'
            :button-text="redirectActionConfig.button_text"
            :type="redirectAction"
          ></MemberButton>
        </div>
        <MemberButton
          v-if="showGoToButton"
          :button-text="getModuleConfig('external_member_customer_bind.not_register.go_to_button_text')"
          :button-url="getModuleConfig('external_member_customer_bind.not_register.go_to_button_url')"
          :button-style='`s-btn-outline-primary`'
          type="URL"
        ></MemberButton>
      <MemberButton
        v-if="showSuccessButton"
        :button-text="getModuleConfig('external_member_customer_bind.not_register.button_text')"
        :button-url="getModuleConfig('external_member_customer_bind.not_register.button_url')"
        :type="getModuleConfig('external_member_customer_bind.not_register.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import registerMixin from "@/mixins/liff/register";
import SharedStep from "@/components/Page/Liff/Shared/Steps";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";

const BUTTON_TYPE = {
  SUCCESS: "success_button",
  REDIRECT: "redirect_button",
};

export default {
  mixins: [registerMixin],
  components: {
    SharedStep,
    MemberButton,
    SharedButton
  },
  computed: {
    ...mapGetters({
      hasIntended: "route/hasIntended",
      buttonDisplayList: "route/buttonDisplayList",
      redirectAction: "route/redirectAction",
    }),
    contentImages() {
      let contentImages = this.getModuleConfig("external_member_customer_bind.not_register.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    contentData() {
      return this.getModuleConfig("external_member_customer_bind.not_register.content") ?? false;
    },
    showPage() {
      return this.getModuleConfig('external_member_customer_bind.not_register.step_section.enable_step_section')
        || this.getModuleConfig('external_member_customer_bind.not_register.form_title')
        || this.getModuleConfig('external_member_customer_bind.not_register.form_desc')
    },
    showGoToButton() {
      if (this.buttonDisplayList.go_to_button != null) {
        return this.buttonDisplayList.go_to_button;
      }
      return this.getModuleConfig('external_member_customer_bind.not_register.go_to_button_text')
    },
    showSuccessButton() {
      if (this.buttonDisplayList.success_button != null) {
        return this.buttonDisplayList.success_button;
      }
      return this.getModuleConfig('external_member_customer_bind.not_register.button_text')
    },
    showRedirectButton() {
      if (this.buttonDisplayList.redirect_button != null) {
        return this.buttonDisplayList.redirect_button;
      }

      if (this.redirectAction) {
        return true
      }

      return this.redirectActionEnabled && this.redirectActionConfig.button_type.includes(BUTTON_TYPE.REDIRECT);
    },
    redirectActionConfig() {
      return this.getModuleConfig("external_member_customer_bind.not_register.redirect_action") || {
        enable: false,
        button_type: [],
      };
    },
    redirectActionEnabled() {
      return this.hasIntended && this.redirectActionConfig.enable;
    },
  },
  async created() {
  },
  beforeMount() {
  },
  methods: {
    ...mapActions({
      intended: "route/intended",
    }),
    redirectToIntended() {
      this.intended();
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}

.item {
  display: flex;
  margin-bottom: 16px;

  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
